import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface Image1 {
  id:number;
  url: string;
  type: string;
  filename: string;
  image_name:string;
  description:string;
}

interface Events {
    day: string;
    date: string;
    time: string;
    name: string;
}

interface HappyHours {
  Sunday: string;
  Monday: string;
  Tuesday: string;
  Wednesday: string;
  Thursday: string;
  Friday: string;
  Saturday: string;
}

interface FeatureIcon {
  url: string;
  type: string;
  filename: string;
};

interface ImportantFeature{
  id: string;
  name: string;
  active:boolean;
  icon: FeatureIcon;
};

interface EstablishmentOffer {
  id: number;
  name: string;
  active:boolean;
  selection_options: string | string[];
  selected_options: string | string[];
  value_type:string;
}

interface EstablishmentOfferItem {
  [key: string]: [string, string | string[]];
}

interface TimeFields {
  opensAt: string;
  closesAt: string;
  closed: boolean;
}

interface WeekTimes {
  [key: string]: TimeFields;
}

interface Account {
  avatar: {
    url: string;
  };
}

export type Weekday = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';

// Customizable Area End

export const configJSON1 = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  editMode:boolean;
  important_feature:ImportantFeature[];
  editModeGallery: boolean;
  image_library:Image1[];
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  id:string;
  avatar: File | null;
  imageUrl:string | null;
  editMode:boolean;
  type:string;
  business_name:string;
  business_contact: string;
  business_email:string;
  business_website:string;
  business_phone:string;
  business_main_contact:string;
  business_address:string;
  business_joined_in:string;
  image_library:Image1[];
  customer_images:Image1[];
  businessEvents:Events[];
  important_feature: ImportantFeature[];
  subSelectedCategories: string[];
  catgory_name: string;
  happyHours2:HappyHours;
  setOpen:boolean;
  currentPhotoIndex:number;
  currentIndex:number;
  eventsPerPage:number;
  selectedPhotoIndex:number;
  uploadedImages: File[];
  imgDescription:string;
  setOpenEditPopup: boolean;
  imgTitle:string;
  successSnackbarEdit:boolean;
  successSnackbarUpload:boolean;
  setOpenDeletePopup:boolean;
  successSnackbarDeleted:boolean;
  invalidDescription:string;
  allowCustomImages:boolean;
  country_code:string;
  city_name:string,
  state_name:string,
  dataEditedPopup:boolean;
  editInfoMode:boolean;
  editFilterMode:boolean;
  editHelpusMode:boolean;
  selectedCategory:string;
  helpusID:string;
  subCategoryData:string[];
  categoryData:string[];
  establishmentOffer1Data:EstablishmentOffer[];
  establishmentOffer2Data:EstablishmentOffer[];
  establishment_offer1:{ [key: string]: [string, string | string[]]; };
  otherOffer:string;
  establishment_offer2:string[];
  editSchedule1Mode:boolean;
  editSchedule2Mode:boolean;
  openPopup1:boolean;
  openPopup2:boolean;
  openingDays: { [key in Weekday]: boolean };
  openingDays2: { [key in Weekday]: boolean };
  setTimes:WeekTimes;  
  setTimes2:WeekTimes;  
  displayedTimes:WeekTimes;
  displayedTimes2:WeekTimes;
  likedByAccounts:Account[];
  totalCheckIns:number;

  invalidBusinessName:string;
  invalidCountryCode:string;
  invalidEmail:string;
  invalidNumber1:string;
  invalidNumber2:string;
  invalidNumber3:string;
  invalidWebsite:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableBusinessController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableBusinessProfileCallId: string = "";
  updateCustomizableBusinessProfileCallId: string = "";
  updateHelpUsHelpYouCallId:string = "";
  getUserListApiCallId:string = "";
  getCustomizableBusinessProfileFieldsCallId: string = "";
  uploadBusinessImagesCallId: string = "";
  editBusinessImagesCallId: string = "";
  deleteImageApiCallId: string = "";
  allowCustomerImagesCallId: string = "";
  businessProfileImageCallId: string = "";
  token: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id:"",
      avatar:null,
      imageUrl:"",
      type:"",
      business_name:"",
      business_contact: "",
      business_email:"",
      business_website:"",
      business_phone:"",
      business_main_contact:"",
      business_address:"",
      business_joined_in:"",
      editMode:false,
      image_library:[],
      customer_images:[],
      businessEvents:[],
      important_feature:[],
      subSelectedCategories:[],
      catgory_name:"",
      happyHours2: {
        Sunday: '',
        Monday: '',
        Tuesday: '',
        Wednesday: '',
        Thursday: '',
        Friday: '',
        Saturday: ''
      },
      setOpen:false,
      currentPhotoIndex:0,
      currentIndex:0,
      eventsPerPage:5,
      selectedPhotoIndex:0,
      uploadedImages: [],
      imgDescription:"",
      setOpenEditPopup:false,
      imgTitle:"",
      successSnackbarEdit:false,
      successSnackbarUpload:false,
      setOpenDeletePopup:false,
      successSnackbarDeleted:false,
      invalidDescription:"",
      allowCustomImages:false,
      country_code:"",
      city_name:"",
      state_name:"",
      dataEditedPopup:false,
      editInfoMode:false,
      editFilterMode:false,
      editHelpusMode:false,
      selectedCategory:"",
      helpusID:"",
      subCategoryData:[],
      categoryData:[],
      establishmentOffer1Data:[],
      establishmentOffer2Data:[],
      establishment_offer1:{},
      otherOffer:"",
      establishment_offer2:[],
      openPopup1: false,
      openPopup2:false,
      editSchedule1Mode:false,
      editSchedule2Mode:false,
      openingDays: {
        Sunday: false,
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
      },
      openingDays2: {
        Sunday: false,
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
      },
      setTimes: this.generateDefaultTimes(false),
      setTimes2: this.generateDefaultTimes(false),
      displayedTimes: this.generateDefaultTimes(true),
      displayedTimes2: this.generateDefaultTimes(true),
      likedByAccounts:[],
      totalCheckIns:0,
     
      invalidBusinessName:"",
      invalidCountryCode:"",
      invalidEmail:"",
      invalidNumber1:"",
      invalidNumber2:"",
      invalidNumber3:"",
      invalidWebsite:"",
      
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getCustomizableBusinessProfile();
    this.getUserList();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const events = [
        { day: "Thursday", date: "March 25th", time: "6:00pm", name: "TRIVIA NIGHT" },
        { day: "Day", date: "Date", time: "6:00pm", name: "Clockwork Lolita" },
        { day: "Thursday", date: "March 25th", time: "6:00pm", name: "Wing Night" },
        { day: "", date: "", time: "", name: "" }
    ];

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getCustomizableBusinessProfileCallId != null &&
    this.getCustomizableBusinessProfileCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const account = responseJson.accounts?.data?.attributes?.account ?? {};
        const { help_us_help_you = {} } = account;

        const transformedData = this.transformSchedule(help_us_help_you.schedule_1, true);
        const transformedData2 = this.transformSchedule(help_us_help_you.schedule_2, true);
        const transformedData3 = this.transformSchedule(help_us_help_you.schedule_2);
        const transformedData4 = this.transformSchedule(help_us_help_you.schedule_1);

        const openingDays = days.reduce((acc: any, day) => {
          const isOpen = transformedData[day].opensAt !== 'Closed';
          acc[day] = isOpen;
          return acc;
        }, {});

        const openingDays2 = days.reduce((acc: any, day) => {
          const isOpen = transformedData2[day].opensAt !== 'Closed';
          acc[day] = isOpen;
          return acc;
        }, {});

        const activeNames = account.custom_field.establishment_offer2.filter((item:EstablishmentOffer) => item.active).map((item: EstablishmentOffer )=> item.name);
        const offer: EstablishmentOfferItem = {};

        account.custom_field.establishment_offer1.forEach((item:EstablishmentOffer) => {
          offer[item.name] = [item.active ? item.name : '', item.selected_options];
        });

        this.setState({
          id: responseJson.accounts?.data?.id,
          business_name: account.business_name,
          business_contact: account.contact_name,
          business_email: account.email,
          business_website: account.website,
          business_phone: account.business_contact_no,
          business_main_contact: account.main_contact_cell_no,
          business_address: account.address,
          business_joined_in: responseJson.accounts?.data?.attributes?.created_at,
          important_feature: account.custom_field.your_filters,
          subSelectedCategories: help_us_help_you?.sub_catgory_name,
          catgory_name: help_us_help_you.catgory_name,
          happyHours2: help_us_help_you.schedule_2,
          displayedTimes: transformedData4,
          displayedTimes2:transformedData3,
          setTimes: transformedData,
          setTimes2:transformedData2,
          openingDays:openingDays,
          openingDays2:openingDays2,
          avatar: this.state.avatar,
          customer_images: responseJson.images.customer_images,
          image_library: responseJson.images.business_images,
          businessEvents: events,
          imgTitle: responseJson.images.business_images[this.state.currentPhotoIndex]?.image_name,
          imgDescription: responseJson.images.business_images[this.state.currentPhotoIndex]?.description,
          allowCustomImages: account.activated,
          country_code: account.country_code,
          city_name: account.city,
          state_name: account.state_name,
          imageUrl: responseJson.accounts?.data.attributes.avatar?.url,
          helpusID:help_us_help_you.id,
          establishment_offer2:activeNames,
          establishment_offer1:offer,
          establishmentOffer2Data:account.custom_field.establishment_offer2,
          establishmentOffer1Data: [
              ...account.custom_field.establishment_offer1,
              {
                id: 'other',
                name: 'Other',
                value_type: 'String',
                selection_options: '',
              },
            ],
          likedByAccounts:responseJson.likes.like_accounts,
          totalCheckIns:responseJson.total_check_ins,
        });
      }
      
      }

      if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.uploadBusinessImagesCallId != null &&
      this.uploadBusinessImagesCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
          if (responseJson) {
            this.setState({ successSnackbarUpload: true});
            setTimeout(() => {
              this.setState({ successSnackbarUpload: false });
            }, 2000);
          }
          this.getCustomizableBusinessProfile();
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.businessProfileImageCallId != null &&
        this.businessProfileImageCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (responseJson) {
              this.setState({ successSnackbarUpload: true});
              setTimeout(() => {
                this.setState({ successSnackbarUpload: false });
              }, 2000);
            }
            this.getCustomizableBusinessProfile();
          }
      
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.editBusinessImagesCallId != null &&
    this.editBusinessImagesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson && !responseJson.errors) {
          this.setState({ setOpenEditPopup: false , successSnackbarEdit: true});
          setTimeout(() => {
            this.setState({ successSnackbarEdit: false });
          }, 2000);
          this.getCustomizableBusinessProfile();
        }else{
          this.setState({invalidDescription: responseJson.errors})
        }
      }

      if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteImageApiCallId != null &&
      this.deleteImageApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
          if (responseJson) {
            this.setState({ setOpenDeletePopup: false , successSnackbarDeleted: true});
            setTimeout(() => {
              this.setState({ successSnackbarDeleted: false });
            }, 1500);
            this.getCustomizableBusinessProfile();
          }
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.updateCustomizableBusinessProfileCallId !== "" &&
        this.updateCustomizableBusinessProfileCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if(responseJson.data){
              this.setState({ editMode: false , editInfoMode: false, editFilterMode: false, editHelpusMode: false});
              this.setState({
                invalidCountryCode: "",
                invalidBusinessName: "",
                invalidEmail: "",
                invalidNumber1: "",
                invalidNumber2: "",
                invalidNumber3: "",
                invalidWebsite: "",
              });
              this.editHelpUSHelpYou();
              this.setState({ dataEditedPopup: true }, () => {
                setTimeout(() => {
                  this.setState({ dataEditedPopup: false });
                }, 1000);
              });
            }else{
              const { error } = responseJson;
              this.setState({
                invalidCountryCode: error.country_code,
                invalidBusinessName: error.business_name,
                invalidEmail: error.email,
                invalidNumber1:error.contact_name,
                invalidNumber2:error.business_contact_no,
                invalidNumber3: error.main_contact_cell_no,
                invalidWebsite: error.website,
              });
            }
        }
        if(getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.getUserListApiCallId !== "" &&
        this.getUserListApiCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) ) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          this.setState({categoryData:responseJson.categories})
          this.setState({subCategoryData:responseJson.sub_categories})
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.updateHelpUsHelpYouCallId !== "" &&
        this.updateHelpUsHelpYouCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if(responseJson){
              this.setState({ editMode: false , editHelpusMode: false, editFilterMode: false });
              this.setState({ dataEditedPopup: true }, () => {
                setTimeout(() => {
                  this.setState({ dataEditedPopup: false });
                }, 1000);
              });
              this.getCustomizableBusinessProfile();
            }
        }
    // Customizable Area End
  }

  // Customizable Area Start

  generateDefaultTimes(closed: boolean) {
    return {
      Sunday: { opensAt: 'Closed', closesAt: 'Closed', closed },
      Monday: { opensAt: 'Closed', closesAt: 'Closed', closed },
      Tuesday: { opensAt: 'Closed', closesAt: 'Closed', closed },
      Wednesday: { opensAt: 'Closed', closesAt: 'Closed', closed },
      Thursday: { opensAt: 'Closed', closesAt: 'Closed', closed },
      Friday: { opensAt: 'Closed', closesAt: 'Closed', closed },
      Saturday: { opensAt: 'Closed', closesAt: 'Closed', closed },
    };
  }

  transformSchedule(schedule: any, convertTo24Hour: boolean = false) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    return days.reduce((acc: any, day) => {
      const value = schedule[day];
      if (value === "Closed") {
        acc[day] = { opensAt: 'Closed', closesAt: 'Closed', closed: true };
      } else {
        const [opensAt, closesAt] = value.split(' - ').map((time: any) => 
          convertTo24Hour ? this.convertTo24HourFormat(time.trim()) : time.trim()
        );
        acc[day] = {
          opensAt: opensAt,
          closesAt: closesAt,
          closed: false
        };
      }
      return acc;
    }, {});
  }

  handleClickOpen = () => {
    this.setState({setOpen:true})
  };

  handleClickClose = () => {
    this.setState({setOpen:false})
  };

  handlePrevImage = () => {
    this.setState((prevState) => {
      const { currentPhotoIndex, currentIndex, eventsPerPage, image_library } = prevState;
      const displayedEvents = image_library.slice(currentIndex, currentIndex + eventsPerPage);
      const newIndex = currentPhotoIndex - 1 < currentIndex ? currentIndex : currentPhotoIndex - 1;
      return {
        currentPhotoIndex: newIndex,
        selectedPhotoIndex: newIndex,
        imgTitle:this.state.image_library[newIndex].image_name,
        imgDescription:image_library[newIndex].description,
      };
    });
  };
  
  handleNextImage = () => {
    this.setState((prevState) => {
      const { currentPhotoIndex, currentIndex, eventsPerPage, image_library } = prevState;
      const displayedEvents = image_library.slice(currentIndex, currentIndex + eventsPerPage);
      const newIndex = currentPhotoIndex + 1 > currentIndex + displayedEvents.length - 1 ? currentIndex + displayedEvents.length - 1 : currentPhotoIndex + 1;
      return {
        currentPhotoIndex: newIndex,
        selectedPhotoIndex: newIndex,
        imgTitle:image_library[newIndex].image_name,
        imgDescription:image_library[newIndex].description,
      };
    });
  };
  

  handleImageClick = (index: number) => {
    this.setState({ currentPhotoIndex: index, selectedPhotoIndex: index, imgTitle:this.state.image_library[index].image_name, imgDescription:this.state.image_library[index].description  });
  };
  
  handlePrevClick = () => {
    this.setState((prevState) => {
      const newIndex = Math.max(prevState.currentIndex - 5, 0);
      return {
        currentIndex: newIndex,
        currentPhotoIndex: newIndex,
        selectedPhotoIndex: newIndex,
        imgTitle:this.state.image_library[newIndex].image_name,
        imgDescription:this.state.image_library[newIndex].description,
      };
    });
  };
  

  handleNextClick = () => {
    this.setState((prevState) => {
      const newIndex = Math.min(prevState.currentIndex + 5, prevState.image_library.length - 1);
      return {
        currentIndex: newIndex,
        currentPhotoIndex: newIndex,
        selectedPhotoIndex: newIndex,
        imgTitle:this.state.image_library[newIndex].image_name,
        imgDescription:this.state.image_library[newIndex].description,
      };
    });
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);

    const newImages = files.map((file, index) => ({
        id: index,
        image_name: file.name,
        url: URL.createObjectURL(file),
        type: file.type,
        filename: file.name,
        description:file.name,
    }));

    this.setState(prevState => {
        const updatedLibrary = [...newImages, ...prevState.image_library];
        const updatedUploadedImages = [...files.reverse()]
        return {
            image_library: updatedLibrary,
            uploadedImages: updatedUploadedImages,
        };
    }, this.uploadImagesBusiness);
  };

  handlePermissionChange = () => {
    this.setState({ allowCustomImages: !this.state.allowCustomImages }, this.allowCustomerImages);
  };

  handleBusinessEditClick = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  handleBusinessPersonalInfoClick = () => {
    this.setState({ editInfoMode: true });
  };

  handleCloseFilterPopup = () => {
    this.setState({ editFilterMode: false });
  };

  handleOpenFilterPopup = () => {
    this.setState({ editFilterMode: true });
  };

  onFormControlChange3 = (name: string, value: [string, string | string[]]) => {
    this.setState(prevState => {
      const updatedEstablishmentOffer = {
        ...prevState.establishment_offer1,
        [name]: value
      };
  
      const updatedEstablishmentOffer1Data = prevState.establishmentOffer1Data.map(item => ({
        ...item,
        selected_options: item.name === name ? value[1] : item.selected_options,
        active: item.name === name ? value[0] === item.name : item.active,
      }));
  
      return {
        establishment_offer1: updatedEstablishmentOffer,
        establishmentOffer1Data: updatedEstablishmentOffer1Data,
      };
    });
  };
  
  onFormControlChange = (categoryName: string) => {
    this.setState((prevState) => {
        const updatedOffers = prevState.establishmentOffer2Data.map(category => {
            if (category.name === categoryName) {
                return { ...category, active: !category.active };
            }
            return category;
        });

        const activeCategories = updatedOffers
            .filter(category => category.active)
            .map(category => category.name);

        return { 
            establishmentOffer2Data: updatedOffers,
            establishment_offer2: activeCategories 
        };
    });
};

  handleHappyHoursEditClick = () => {
    this.setState({ editSchedule1Mode: !this.state.editSchedule1Mode });
  };

  handleHappyHoursEditClick2 = () => {
    this.setState({ editSchedule2Mode: !this.state.editSchedule2Mode });
  };

  handleClickOpenPopup = () => {
    this.setState({ openPopup1: true });
  };
  handleClosePopup= () => {
    this.setState({ openPopup1: false , editSchedule1Mode: false});
  };

  handleClickOpenPopup2 = () => {
    this.setState({ openPopup2: true });
  };
  handleClosePopup2= () => {
    this.setState({ openPopup2: false , editSchedule2Mode: false});
  };

  handleCheckboxChange = (day: string, checked: boolean) => {
    this.setState(prevState => ({
      openingDays: {
        ...prevState.openingDays,
        [day]: checked
      }
    }));
  };

  handleCheckboxChange2 = (day: string, checked: boolean) => {
    this.setState(prevState => ({
      openingDays2: {
        ...prevState.openingDays2,
        [day]: checked
      }
    }));
  };

  handleTimeChange = (stateKey: 'setTimes' | 'setTimes2', day: Weekday, field: keyof TimeFields) => 
    (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState((prevState) => ({
        ...prevState,
        [stateKey]: {
          ...prevState[stateKey],
          [day]: { 
            ...prevState[stateKey][day], 
            [field]: event.target.value 
          }
        }
      }));
    };

  formatTime = (setTimes: string) => {
    if (setTimes === 'Closed') return 'Closed';
    const [hours, minutes] = setTimes.split(':');
    const period = parseInt(hours) >= 12 ? 'pm' : 'am';
    const formattedHours = ((parseInt(hours) % 12) || 12).toString().padStart(2, '0');
    const formattedMinutes = minutes.padStart(2, '0');
    return `${formattedHours}:${formattedMinutes} ${period}`;
  };

  convertTo24HourFormat = (time12h: any) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');

    if (modifier === 'pm' && hours !== '12') {
      hours = parseInt(hours, 10) + 12;
    } else if (modifier === 'am' && hours === '12') {
      hours = '00';
    }

    return `${hours.toString().padStart(2, '0')}:${minutes}`;
  };

  handleSubmitPopupGeneric = (
    setTimesKey: 'setTimes' | 'setTimes2',
    displayedTimesKey: 'displayedTimes' | 'displayedTimes2',
    openingDaysKey: 'openingDays' | 'openingDays2',
    openPopupKey: 'openPopup1' | 'openPopup2',
    editScheduleModeKey: 'editSchedule1Mode' | 'editSchedule2Mode'
  ) => {
    const setTimes = this.state[setTimesKey];
    const displayedTimes = this.state[displayedTimesKey];
    const openingDays = this.state[openingDaysKey];
    const updatedTimes: WeekTimes = {};
  
    (Object.keys(setTimes) as Weekday[]).forEach(day => {
      const dayTimes = setTimes[day];
      const isClosed = !openingDays[day];
      const formattedOpensAt = isClosed ? 'Closed' : this.formatTime(dayTimes.opensAt);
      const formattedClosesAt = isClosed ? 'Closed' : this.formatTime(dayTimes.closesAt);
      updatedTimes[day] = {
        opensAt: formattedOpensAt,
        closesAt: formattedClosesAt,
        closed: isClosed,
      };
    });
  
    this.setState((prevState) => ({
      ...prevState,
      [displayedTimesKey]: {
        ...prevState[displayedTimesKey],
        ...updatedTimes
      },
      [openPopupKey]: false,
      [editScheduleModeKey]: false,
    }), () => {
      this.editHelpUSHelpYou();
    });
  };
  
  handleSubmitPopup = () => {
    this.handleSubmitPopupGeneric('setTimes', 'displayedTimes', 'openingDays', 'openPopup1', 'editSchedule1Mode');
  };
  
  handleSubmitPopup2 = () => {
    this.handleSubmitPopupGeneric('setTimes2', 'displayedTimes2', 'openingDays2', 'openPopup2', 'editSchedule2Mode');
  };
  
  handleOtherCategoryChange2 = (text: string) => {
    this.setState({ otherOffer: text });
  };

  prepareScheduleDataGeneric = (
    displayedTimesKey: 'displayedTimes' | 'displayedTimes2',
    scheduleKey: 'schedule_1' | 'schedule_2'
  ) => {
    const displayedTimes = this.state[displayedTimesKey];
    const scheduleData = Object.keys(displayedTimes).reduce((acc, day) => {
      const { opensAt, closesAt } = displayedTimes[day];
      acc[day] = opensAt === 'Closed' ? 'Closed' : `${opensAt} - ${closesAt}`;
      return acc;
    }, {} as { [key: string]: string });
  
    return {
      [scheduleKey]: scheduleData
    };
  };
  
  prepareScheduleData = () => {
    return this.prepareScheduleDataGeneric('displayedTimes', 'schedule_1');
  };
  
  prepareScheduleData2 = () => {
    return this.prepareScheduleDataGeneric('displayedTimes2', 'schedule_2');
  };

  prepareDataForAPI = () => {
    const { establishment_offer1 } = this.state;
    return Object.keys(establishment_offer1).map((key) => {
      const [radioValue, dropdownValue] = establishment_offer1[key];
      if (key === "Other") {
        return null;
      }
      if (radioValue !== "") {
        return {
          name: key,
          selected_options: dropdownValue,
        };
      }
      return null;
    }).filter((item) => item !== null);
  }

  handleHelpUsClick= () => {
    this.setState({ editHelpusMode: !this.state.editHelpusMode });
  };

  
  handleBusinessUploadImgChange = (event: any) => {

    const file = event.target.files?.[0];
    
    if (file) {
      const reader = new FileReader();
  
      reader.onload = () => {
        this.setState(
          prevState => ({
            ...prevState,
            imageUrl: reader.result as string,
            avatar: file
          }),
          () => {
            this.handleBusinessInputChange('avatar', file);
            this.uploadBusinessProfileImage();
          }
        );
      }
      reader.readAsDataURL(file);
    }
    
  };

  handleBusinessInputChange = (key: string, value: string ) => {
    this.setState(prevState => ({
          ...prevState,
          [key]: value
  }));
  };

  handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({catgory_name: event.target.value});
  };

  handleSubCategoryChange = (value: string) => {

    let updatedCategories: string[] = [...this.state.subSelectedCategories];
    
    if (updatedCategories.includes(value)) {
        updatedCategories = updatedCategories.filter(category => category !== value);
    } else {
        updatedCategories.push(value);
    }

    this.setState({ subSelectedCategories: updatedCategories });
};

  handleClickOpenEditPopup = () => {
    this.setState({setOpenEditPopup:true})
  };

  handleCloseEditPopup= () => {
    this.setState({setOpenEditPopup:false})
  };

  handleDescriptionChangeImage = (text: string) => {
    this.setState({imgDescription: text});
  };

  handleTitleChangeImage= (text: string) => {
    this.setState({imgTitle: text});
  };


  handleClickOpenDeletePopup = () => {
    this.setState({setOpenDeletePopup:true})
  };

  handleCloseDeletePopup= () => {
    this.setState({setOpenDeletePopup:false})
  };

  getCustomizableBusinessProfile = () => {

    let Token = window.localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON1.searchApiContentType,
      token: Token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableBusinessProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON1.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON1.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  uploadImagesBusiness = () => {
    let Token = window.localStorage.getItem("token")
    const header = {
      token: Token
    };

    const { uploadedImages } = this.state;

    const formData = new FormData();
    uploadedImages.forEach((file) => {
      formData.append('images[]',file);
    });
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.uploadBusinessImagesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON1.uploadBusinessImagesEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON1.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editBusinessImages = (id:number) => {

    let Token = window.localStorage.getItem("token")
    const header = {
      token: Token
    };

    const data = new FormData();
    data.append('title', this.state.imgTitle);
    data.append('description', this.state.imgDescription);
   
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editBusinessImagesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON1.uploadBusinessImagesEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON1.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteImage = (imageId:number) => {

    let Token = window.localStorage.getItem("token")
    const header = {
      token: Token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteImageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON1.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON1.uploadBusinessImagesEndPoint + "/" + `${imageId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  allowCustomerImages = () => {
    let Token = window.localStorage.getItem("token")
    const header = {
      token: Token
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.allowCustomerImagesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON1.allowCustomerImgEndPoint}?activated=${this.state.allowCustomImages}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON1.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editAllBusinessCustomisableProfile = () => {

    this.editBusinessCustomisableProfile();

  }

  editBusinessCustomisableProfile = () => {
    let Token = window.localStorage.getItem("token")
    const header = {
      token: Token
    };

    const data = new FormData();
    data.append('email', this.state.business_email);
    data.append('business_name', this.state.business_name);
    data.append('address', this.state.business_address);
    data.append('contact_name', this.state.business_contact);
    if(this.state.business_website){
      data.append('website', this.state.business_website);
    }
    data.append('business_contact_no', this.state.business_phone);
    data.append('main_contact_cell_no', this.state.business_main_contact);
    data.append('created_at', this.state.business_joined_in);
    data.append('country_code', this.state.country_code);
    data.append('state_name', this.state.state_name);
    data.append('city', this.state.city_name);
  
    if(this.state.important_feature){
      data.append('important_feature', this.state.important_feature.join(','));
    }
    data.append("type", "business_account");

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableBusinessProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON1.updateCustomizableProfileEndPoint}${this.state.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON1.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  editHelpUSHelpYou = () => {
    let Token = window.localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON1.searchApiContentType,
      token: Token
    };

    const dataToSend = this.prepareDataForAPI();
    const schedule_1 = this.prepareScheduleData();
    const schedule_2 = this.prepareScheduleData2();

    const helpUsData = {
      help_us_help_you: {
        catgory_name: this.state.catgory_name,
        sub_catgory_name: [...this.state.subSelectedCategories],
        establishment_offer1: dataToSend,
        establishment_offer2: [...this.state.establishment_offer2, this.state.otherOffer],
        ...schedule_1,
        ...schedule_2
      }
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateHelpUsHelpYouCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON1.updateHelpUsHelpYouEndPoint}${this.state.helpusID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(helpUsData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON1.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserList = () => {

    const header = {
      "Content-Type": configJSON1.getUserListApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON1.updateHelpUsHelpYouEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON1.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  uploadBusinessProfileImage = () => {
    let Token = window.localStorage.getItem("token")
    const header = {
      token: Token
    };

    const data = new FormData();
  
    data.append('address', this.state.business_address);
    data.append('country_code', this.state.country_code);
    data.append('state_name', this.state.state_name);
    data.append('city', this.state.city_name);
    data.append("type", "business_account");
    if(this.state.avatar){
      data.append('avatar', this.state.avatar);
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.businessProfileImageCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON1.updateCustomizableProfileEndPoint}${this.state.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON1.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}