import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface Events {
  day: string;
  date: string;
  time: string;
  name: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  businessDetails:any;
  helpUsHelpYouDetails:any;
  imageLibrary:any;
  businessEvents: Events[];
  currentIndex: number;
  eventsPerPage: number;
  id:string;
  subCategoryName:string[];
  happyHours1:any;
  happyHours2:any;
  hoursValue:string;
  setOpen:boolean;
  setOpenShare:boolean;
  setOpenReport:boolean;
  flagReason:string,
  flagDescription:string;
  businessLogo:string;
  businessBackground:string;
  loginToken:string;
  businessLiked:boolean;
  loginSnackbar:boolean;
  successSnackbarFlag:boolean;
  shareUrl:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      id:"",
      businessDetails:[],
      helpUsHelpYouDetails:[],
      businessEvents: [],
      imageLibrary:[],
      currentIndex: 0,
      eventsPerPage: 4,
      subCategoryName:[],
      happyHours1:[],
      happyHours2:[],
      hoursValue:"1",
      setOpen:false,
      setOpenShare:false,
      setOpenReport:false,
      flagReason:"",
      flagDescription:"", 
      businessLogo:"",
      businessBackground:"",
      loginToken:"",
      businessLiked:false,
      loginSnackbar:false,
      successSnackbarFlag:false,
      shareUrl:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const token = window.localStorage.getItem("token")
    if (token){
      this.setState({loginToken: token})
    }

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      // let token = localStorage.getItem('authToken');
      this.setState({ token: token });
      this.getListRequest(token);
      const events = [
        {
          day: "Thursday",
          date: "March 25th",
          time: "6:00pm",
          name: "TRIVIA NIGHT",
        },
        { day: "Day", date: "Date", time: "6:00pm", name: "Clockwork Lolita" },
        {
          day: "Thursday",
          date: "March 25th",
          time: "6:00pm",
          name: "Wing Night",
        },
        { day: "", date: "", time: "", name: "" },
        { day: "", date: "", time: "", name: "" },
        { day: "", date: "", time: "", name: "" },
      ];
      this.setState({ businessEvents: events });
      this.getBusinessDetail();
    }

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
  
      if (responseJson && !responseJson.errors) {
        this.setState({
          arrayHolder: responseJson.data,
          businessDetails: responseJson.accounts.data.attributes.account,
          helpUsHelpYouDetails: responseJson.accounts.data.attributes.account.help_us_help_you,
          imageLibrary: responseJson.images.slice(0, 10),
          subCategoryName: responseJson.accounts.data.attributes.account.help_us_help_you.sub_catgory_name,
          happyHours1: responseJson.accounts.data.attributes.account.help_us_help_you.schedule_1,
          happyHours2: responseJson.accounts.data.attributes.account.help_us_help_you.schedule_2,
          businessLogo:  responseJson.accounts.data.attributes.avatar.url ,
          businessBackground: responseJson.accounts.data.attributes.profile_image.url,
          businessLiked:responseJson.like
        });
        
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.reportBusinessCallId != null &&
      this.reportBusinessCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      if (responseJson && !responseJson.errors) {
        this.setState({ successSnackbarFlag: true, setOpenReport: false,});
        setTimeout(() => {
          this.setState({ successSnackbarFlag: false });
        }, 2000);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getShareUrlCallId != null &&
      this.getShareUrlCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      if (responseJson && !responseJson.errors) {
        this.setState({ shareUrl: responseJson.link });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  likeBusinessCallId: string = "";
  reportBusinessCallId: string = "";
  getShareUrlCallId: string = "";

  likedBusinessAccount = () => {

    let Token = window.localStorage.getItem("token");
    
    let business_Id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

    const header = {
      token: Token
    };

    const formData = new FormData();

    if(business_Id){
      formData.append("likeable_id", business_Id);
    }
    formData.append("active_like", this.state.businessLiked.toString());

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.likeBusinessCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.businessLikeEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  reportBusinessAccount = () => {

    let Token = window.localStorage.getItem("token");
    let business_Id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

    const header = {
      token: Token
    };

    const formData = new FormData();

    if(business_Id){
      formData.append("receiver_id", business_Id);
    }
    formData.append("reason", this.state.flagReason);
    formData.append("description", this.state.flagDescription);

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.reportBusinessCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reportAccountEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleLikedChange = () => {
    this.setState(
      { businessLiked: !this.state.businessLiked },
      () => {
        this.likedBusinessAccount();
      }
    );    
  };

  pleaseLoginPopup = () => {
    this.setState({ loginSnackbar: true }, () => {
      setTimeout(() => {
        this.setState({ loginSnackbar: false });
      }, 2000);
    });
  };

  handlePrevClick = () => {
    this.setState((prevState) => ({
      currentIndex: Math.max(prevState.currentIndex - 1, 0),
    }));
  };

  handleNextClick = () => {
    this.setState((prevState) => ({
      currentIndex: Math.min(
        prevState.currentIndex + 1,
        prevState.businessEvents.length - 1
      ),
    }));
  };

  getBusinessDetail = () => {

    let business_Id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
    const currentUserId = window.localStorage.getItem("userId");

    const header = {
      "Content-Type": configJSON.productApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.businessDataAPIEndpoint}?id=${business_Id}&current_user_id=${currentUserId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getShareUrlFunc = () =>{
    let business_Id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

    const header = {
      "Content-Type": configJSON.productApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getShareUrlCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShareUrlEndPoint}?id=${business_Id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleHourChange = (event: React.SyntheticEvent, newValue: string) => {
    this.setState({hoursValue:newValue})
  };

  handleClickOpen = () => {
    this.setState({setOpen:true})
  };

  handleClickOpenSharePopup = () => {
    this.setState({setOpenShare:true})
    this.getShareUrlFunc();
  };

  handleClickOpenReport = () => {
    this.setState({setOpenReport:true})
  };

  handleClose = () => {
    this.setState({setOpen:false})
    this.setState({setOpenShare:false})
    this.setState({setOpenReport:false})
  };

  handleDescriptionChangeDirectory = (text: string) => {
    this.setState({flagDescription: text});
  };

  handleFlagReasonChange = (value: string) => {
    this.setState({ flagReason:value });
  };

  // Customizable Area End
}
